import * as axios from 'axios';
import { DiplomaCategory, DiplomaType } from './modules/diploma/domain/types';
import { RoleInterface } from './modules/users/domain/Roles';
import { FilterColumnOption } from './components/FilterColumn';
import { ExamReportEntry } from './modules/exams/domain/types';

export interface HydraCollectionResponse<T> extends axios.AxiosResponse {
  data: {
    'hydra:member': T[];
    'hydra:totalItems': number;
  };
}

export interface DataCollection<T> {
  items: T[];
  totalItems: number;
  perPage?: number;
}

export interface CollectionResponse<T> extends axios.AxiosResponse {
  data: DataCollection<T>;
}

export interface ApiFilterCriteria {
  query?: string | null;
  order?: {
    field: string;
    order: 'asc' | 'desc';
  }[];
  filters?: { [key: string]: string | number | boolean | FilterColumnOption[] };
}

export interface Repository<T> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
    perPage?: number,
  ): Promise<
    HydraCollectionResponse<T> | CollectionResponse<T> | DataCollection<T>
  >;

  delete?(id: string): Promise<axios.AxiosResponse>;
}

export interface TokenResponse extends axios.AxiosResponse {
  data: {
    token: string;
    ttl: number;
    refresh_token: string;
    refresh_token_ttl: number;
  };
}

export interface Address {
  street: string;
  number: string;
  suffix?: string;
  postalCode: string;
  town: string;
  province: string;
  country?: string;
  lat?: number;
  lon?: number;
  lat_lon_searched_on?: Date;
}

export interface DelegateAddressData {
  homeAddress: Address | null;
  holidayAddress: Address | null;
}

export interface GeocodeDto {
  lat: number | undefined;
  lon: number | undefined;
}

export interface MinimalSwimmingLessonProvider {
  id: string;
  name: string;
}

export interface LessonLocation {
  id: string;
  name: string;
  email?: string;
  telephone?: string;
  visitingAddress: Address;
  swimmingLessonProvider: MinimalSwimmingLessonProvider;
  licenseSwimAbc: boolean;
  licenseSnorkeling: boolean;
  licenseSurvival: boolean;
  mostRecentVisitDate?: string;
}

export interface MinimalLessonLocation {
  id: string;
  name: string;
}

export type SwimmingLessonProviderType =
  | 'regular_swimming_lesson_provider'
  | 'independent_lesson_location';

export interface SwimmingLessonProvider {
  id: string;
  name: string;
  afasId?: string;
  type?: SwimmingLessonProviderType;
  typeReadable?: string;
  email?: string;
  phoneNumber?: string;
  trainingInstituteC: boolean;
  custodialInstitution: boolean;
  centralExams: boolean;
  attention: boolean;
  inactive?: boolean;
  external?: boolean;
  visitingAddress?: Address;
  shippingAddress?: Address;
  lessonLocations: MinimalLessonLocation[];
  examLocations: ExamLocation[];
  manager?: MinimalUser;
  examiners: Examiner[];
  customizedDiplomaAPdf?: File;
  customizedDiplomaBPdf?: File;
  customizedDiplomaCPdf?: File;
}

export interface Operator {
  id: string;
  name: string;
  swimmingLessonProviderCount: number;
  deleted?: boolean;
}

export interface OperatorDTO {
  id: string;
  name: string;
  swimmingLessonProviderCount: number;
  deleted?: boolean;
}

export interface SwimmingLessonProviderDTO {
  id: string;
  name: string;
  afasId: string | '';
  type: SwimmingLessonProviderType | '';
  typeReadable: string | '';
  email: string | '';
  phoneNumber: string | '';
  trainingInstituteC: boolean;
  custodialInstitution: boolean;
  centralExams: boolean;
  attention: boolean;
  visitingAddress: Address | null;
  shippingAddress: Address | null;
  lessonLocations: MinimalLessonLocation[];
  examLocations: ExamLocation[];
  manager: MinimalUser | null;
  examiners: Examiner[];
  customizedDiplomaAPdf?: File;
  customizedDiplomaAPdfId: string;
  customizedDiplomaBPdf?: File;
  customizedDiplomaBPdfId: string;
  customizedDiplomaCPdf?: File;
  customizedDiplomaCPdfId: string;
}

export interface SwimmingLessonProviderWithLessonLocations {
  id: string;
  name: string;
  afasId?: string;
  lessonLocations: MinimalLessonLocation[];
  centralExams: boolean;
}

export type DelegateStatus = 'delegate' | 'mentor' | 'aspirant';

export interface DelegateProfile {
  id: string;
  startDate: string;
  status: DelegateStatus;
  statusReadable: string;
  licenseSwimAbc: boolean;
  licenseSnorkeling: boolean;
  licenseSurvival: boolean;
  iban?: string;
  mayInvoice: boolean;
  maxDistanceExamsInKm?: number;
  available: boolean;
  holidayFrom?: Date | null;
  holidayUntil?: Date | null;
  availableOnHoliday?: boolean;
  holidayAddress?: Address;
  holidayPostalCodeRadiusInKm?: number | null;
}

export interface DelegateHolidayPeriod {
  from: Date | null;
  until: Date | null;
  available: boolean;
  address: Address | null;
  radius: number | null;
}
export interface DelegateDTO {
  id: string;
  mayInvoice: boolean;
  maxDistanceExamsInKm: number;
}

export interface User {
  id: string;
  afasId?: string;
  roles: (keyof RoleInterface)[];
  explicitRoles?: (keyof RoleInterface)[];
  email: string;
  firstName: string;
  insertion: string | null;
  lastName: string;
  active: boolean;
  fullName: string;
  fullNameLastNameFirst?: string;
  telephoneWork?: string | null;
  telephonePrivate?: string | null;
  telephonePrivateMobile?: string | null;
  iban?: string | null;
  password?: string;
  swimmingLessonProvider?: SwimmingLessonProviderWithLessonLocations;
  visitingAddress?: Address | null;
  delegateProfile?: DelegateProfile;
  operator?: Operator;
}

export interface UserDTO {
  id: string | null;
  email: string;
  swimmingLessonProviderId: string | null;
  firstName: string;
  insertion: string | null;
  lastName: string;
  delegateProfile?: DelegateDTO;
  operatorId: string | null;
}

export interface MinimalUser {
  id: string;
  email: string;
  fullName: string;
  fullNameLastNameFirst: string;
  delegateProfile: DelegateProfile;
}

export interface ExamDiploma {
  id: string;
  diplomaTypeId: string;
  examinerId: string;
  beginTime: string;
  endTime: string;
  numberParticipants: number;
  numberPassed: number;
  diplomaType: DiplomaType;
  examiner: Examiner;
  hasDelegate?: boolean;
}

export interface ExamLocation {
  id: string;
  name: string;
  email: string;
  telephone: string;
  visitingAddress: Address | null;
}

export interface Exam {
  id: string;
  examNumber: number;
  paddedExamNumber: string;
  date: string;
  examLocation: ExamLocation;
  swimmingLessonProvider: SwimmingLessonProvider;
  examLines: ExamDiploma[];
  customizedDiplomaTypePdfs?: File[];
  examiner?: MinimalUser;
  confirmedDelegates?: MinimalUser[];
  invitedDelegates?: MinimalUser[];
  aspirant?: MinimalUser;
  menOnly: boolean;
  womenOnly: boolean;
  lessonLocations: MinimalLessonLocation[];
  reports?: ExamReportEntry[];
  declarations?: Declaration[];
  highPriority?: boolean;
}

export interface ConflictingExam {
  beginTime: string;
  endTime: string;
}

export interface ExaminerLicense {
  id: string;
  diplomaCategory: DiplomaCategory;
  obtainedAt: string;
  isValid: boolean;
}

export interface Examiner {
  id: string;
  email: string;
  fullName: string;
  extendedName: string;
  telephoneWork?: string;
  telephonePrivate?: string;
  telephonePrivateMobile?: string;
  examinerLicenses?: ExaminerLicense[];
}

export interface Delegate extends User {
  delegateProfile: DelegateProfile;
}

export interface DelegateTimeSlot {
  id: string;
  dayOfWeek: number;
  from: string;
  until: string;
}

export interface DelegateTimeSlotDto {
  dayOfWeek: number;
  from: Date | null;
  until: Date | null;
}

export type ExamDelegateAssignmentStatus =
  | 'invited'
  | 'declined'
  | 'confirmed'
  | 'cancelled';

export interface ExamAssignmentOfDelegate {
  id: string;
  exam: Exam;
  status: ExamDelegateAssignmentStatus;
  examLines: ExamDiploma[];
  invitedAt: string;
}

export interface DelegateAssignmentOfExam {
  id: string;
  delegate: MinimalUser;
  status: ExamDelegateAssignmentStatus;
  cancelledAt?: string;
  confirmedAt?: string;
  declinedAt?: string;
  invitedAt?: string;
  invitedManually: boolean;
  visited: boolean;
}

export interface File {
  id: string;
  name: string;
  size: number;
  mimeType: string;
  created: string;
  read: boolean;
  user?: MinimalUser;
}

export interface Notification {
  id: string;
  type: string;
  message: string;
  references: { [key: string]: string };
  read: boolean;
  createdAt: string;
}

export interface ValidationViolation {
  message: string;
  propertyPath: string;
}

export interface ValidationFailedResponse extends axios.AxiosResponse {
  data: {
    detail: 'validation_failed';
    violations: ValidationViolation[];
  };
}

export enum DeclarationStatus {
  open = 'Nieuw',
  approved = 'Goedgekeurd',
  exported = 'Geëxporteerd',
}

export enum DeclarationTravelMethod {
  own = 'Eigen vervoer',
  public_transport = 'Openbaar vervoer',
}

export interface Declaration {
  id: string;
  delegate: User;
  exam?: Exam;
  status: keyof typeof DeclarationStatus;
  travelMethod: keyof typeof DeclarationTravelMethod;
  baseCompensation: number;
  travelCompensation: number;
  additionalCosts: number;
  totalCompensation: number;
  kilometers: number | null;
  kilometerCompensation: number;
  explanation: string | null;
  creditDescription: string;
  descriptionForSepa: string;
  approvedOn: string | null;
  exportedOn: string | null;
  attachments: File[];
}

export interface DeclarationDto {
  id: string | null;
  examId: string;
  delegateId: string;
  baseCompensation: number;
  travelMethod: keyof typeof DeclarationTravelMethod | null;
  travelCompensation: number | null;
  additionalCosts: number;
  kilometers: number | null;
  kilometerCompensation: number;
  explanation: string | null;
  creditDescription: string;
  attachments: string[];
}

export interface ManagementQuery {
  id: number;
  title: string;
  description: string;
}

export interface Sepa {
  id: string;
  createdAt: string;
  totalAmount: number;
  declarations: Declaration[];
}
