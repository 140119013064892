import { AxiosResponse } from 'axios';
import {
  ApiFilterCriteria,
  CollectionResponse,
  Delegate,
  DelegateTimeSlot,
  DelegateTimeSlotDto,
  DelegateHolidayPeriod,
  Repository,
  SwimmingLessonProvider,
  DelegateAddressData,
  GeocodeDto,
} from '../../../types';
import ApiClient from '../../../api/ApiClient';
import { parseCriteria } from '../../../utils/common';

class DelegateRepository implements Repository<Delegate> {
  findBy(
    criteria?: ApiFilterCriteria,
    page?: number,
  ): Promise<CollectionResponse<Delegate>> {
    const params = parseCriteria(criteria, { page: page || 0 });

    return ApiClient.get('/api/v1/delegates', params);
  }

  get(id: string): Promise<AxiosResponse<Delegate>> {
    return ApiClient.get(`/api/v1/delegates/${id}`);
  }

  fetchAll(): Promise<CollectionResponse<Delegate>> {
    return ApiClient.get('/api/v1/delegates', {
      page: 1,
      perPage: 9999,
    });
  }

  getTimeSlots(id: string): Promise<AxiosResponse<DelegateTimeSlot[]>> {
    return ApiClient.get(`/api/v1/delegates/${id}/time-slots`);
  }

  addTimeSlot(
    id: string,
    timeSlot: DelegateTimeSlotDto,
  ): Promise<AxiosResponse> {
    return ApiClient.post(`/api/v1/delegates/${id}/time-slots`, timeSlot);
  }

  deleteTimeSlot(id: string, timeSlotId: string): Promise<AxiosResponse> {
    return ApiClient.delete(`/api/v1/delegates/${id}/time-slots/${timeSlotId}`);
  }

  saveHolidayPeriod(
    id: string,
    holidayPeriod: DelegateHolidayPeriod,
  ): Promise<AxiosResponse> {
    return ApiClient.post(
      `/api/v1/delegates/${id}/holiday-period`,
      holidayPeriod,
    );
  }

  getBlockedSwimmingLessonProviders(
    id: string,
  ): Promise<AxiosResponse<SwimmingLessonProvider[]>> {
    return ApiClient.get(
      `/api/v1/delegates/${id}/blocked-swimming-lesson-providers`,
    );
  }

  getAddressData(id: string): Promise<AxiosResponse<DelegateAddressData>> {
    return ApiClient.get(`/api/v1/delegates/${id}/address-data`);
  }

  updateGeocode(
    id: string,
    addressType: 'homeAddress' | 'holidayAddress',
    dto: GeocodeDto,
  ): Promise<AxiosResponse> {
    return ApiClient.put(`/api/v1/delegates/${id}/geocode`, {
      addressType,
      ...dto,
    });
  }
}

export default DelegateRepository;
