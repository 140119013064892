import { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { DelegateAddressData, GeocodeDto, User } from '../../../types';
import DelegateRepository from '../../delegates/repository/DelegateRepository';
import DelegateAddressSection from './DelegateAddressSection';

interface DelegateGeocodeProps {
  user: User;
}

const DelegateGeocode = (props: DelegateGeocodeProps) => {
  const { user } = props;

  const notifications = useSnackbar();

  const [addressData, setAddressData] = useState<
    DelegateAddressData | undefined
  >(undefined);

  const repository = useMemo(() => new DelegateRepository(), []);

  useEffect(() => {
    repository
      .getAddressData(user.id)
      .then((response) => {
        setAddressData(response.data);
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het ophalen van de adresgegevens.',
          {
            variant: 'error',
          },
        );
      });
  }, []);

  if (addressData === undefined) {
    return null;
  }

  const { homeAddress, holidayAddress } = addressData;

  const updateLatLon = (
    addressType: 'homeAddress' | 'holidayAddress',
    geocode: GeocodeDto,
  ) => {
    repository
      .updateGeocode(user.id, addressType, geocode)
      .then(() => {
        notifications.enqueueSnackbar('De geocode is succesvol bijgewerkt.', {
          variant: 'success',
        });
      })
      .catch(() => {
        notifications.enqueueSnackbar(
          'Er ging iets mis bij het bijwerken van de geocode.',
          {
            variant: 'error',
          },
        );
      });
  };

  return (
    <>
      {homeAddress && homeAddress.street && (
        <DelegateAddressSection
          title="Woonadres"
          address={homeAddress}
          onSubmit={(geocode) => updateLatLon('homeAddress', geocode)}
        />
      )}
      {holidayAddress && holidayAddress.street && (
        <DelegateAddressSection
          title="Vakantieadres"
          address={holidayAddress}
          onSubmit={(geocode) => updateLatLon('holidayAddress', geocode)}
        />
      )}
    </>
  );
};

export default DelegateGeocode;
