import React, { useState } from 'react';

import { Address, GeocodeDto } from '../../../types';
import TextOutput from '../../../components/form/TextOutput';
import FormSection from '../../../components/form/FormSection';
import { Box, Button, TextField } from '@material-ui/core';

interface DelegateAddressSectionProps {
  title: string;
  address: Address;
  onSubmit: (geocode: GeocodeDto) => void;
}

const DelegateAddressSection = (props: DelegateAddressSectionProps) => {
  const [geocodeDto, setGeocodeDto] = useState<GeocodeDto>({
    lat: props.address.lat,
    lon: props.address.lon,
  });

  const makeAddress = (street: string, number: string, suffix?: string) => {
    if (street.length === 0) {
      return 'Onbekend';
    }

    return `${street} ${number} ${suffix || ''}`;
  };

  const makePostalCodeTown = (postalCode: string, town: string) => {
    if (postalCode.length === 0 && town.length === 0) {
      return 'Onbekend';
    }

    return `${postalCode} ${town}`;
  };

  const updateLat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseFloat(event.target.value);
    setGeocodeDto((prev) => ({
      ...prev,
      lat: Number.isNaN(value) ? undefined : value,
    }));
  };

  const updateLon = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseFloat(event.target.value);
    setGeocodeDto((prev) => ({
      ...prev,
      lon: Number.isNaN(value) ? undefined : value,
    }));
  };

  const handleSubmit = () => {
    if (!geocodeDto.lat || !geocodeDto.lon) {
      return;
    }

    props.onSubmit(geocodeDto);
  };

  return (
    <FormSection title={props.title}>
      <TextOutput
        label="Adres"
        value={makeAddress(
          props.address.street,
          props.address.number,
          props.address.suffix,
        )}
      />
      <TextOutput
        label="Postcode en plaats"
        value={makePostalCodeTown(props.address.postalCode, props.address.town)}
      />
      <div>
        <TextField
          type="number"
          label="Breedtegraad"
          defaultValue={props.address.lat}
          name="latitude"
          onChange={updateLat}
          required
        />
        <TextField
          type="number"
          label="Lengtegraad"
          defaultValue={props.address.lon}
          name="longitude"
          onChange={updateLon}
          required
        />
      </div>
      {geocodeDto.lat && geocodeDto.lon && (
        <a
          href={`https://www.google.com/maps/@${geocodeDto.lat},${geocodeDto.lon},17z`}
          target="_blank"
        >
          Bekijken in Google Maps
        </a>
      )}
      <Box mt={2}>
        <Button
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="primary"
          disabled={!geocodeDto.lat || !geocodeDto.lon}
        >
          Opslaan
        </Button>
      </Box>
    </FormSection>
  );
};

export default DelegateAddressSection;
